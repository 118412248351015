* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  background: linear-gradient(90deg, rgba(21,23,64,1) 0%, rgba(38,60,62,1) 50%, rgba(21,23,64,1) 100%);
}

/* Desktop Menu */

.desktop-menu {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 550px) {
  .desktop-menu {
      visibility: hidden;
      display: none;
  }
}

.header-jt {
  color: #ffffff;
  font-family: 'Fugaz One', cursive;
  font-size: 4vw;
  margin: 2.5vw 0 0 3vw;
}

@media screen and (max-width: 550px) {
  .header-jt {
      font-size: 5vw;
  }
}

.header-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  margin-top: 4.2vw;
  margin-right: 3vw;
  width: 60vw;
}

.tab {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.4vw;
}

.tab.active {
  text-decoration: underline wavy;
  font-weight: 700;
  text-underline-offset: 0.5rem;
}

.tab:hover {
  opacity: 0.5;
}

.tab.active:hover {
  opacity: 1;
}

@media screen and (max-width: 1000px) {
  .tab {
      font-size: 2vw;
  }
}

/* Mobile Menu */

.mobile-menu {
  visibility: hidden;
  justify-content: space-between;
  display: none;
}

@media screen and (max-width: 550px) {
  .mobile-menu {
    visibility: visible;  
    display: flex;
  }
}

.menu-btn {
  background: transparent;
  color: #ffffff;
  border: 1px solid white;
  border-radius: 0.6vw;
  padding: 0 .6vw .3vw;
  font-size: 4vw;
  transition: all 0.4s ease;
  height: fit-content;
  margin: 2.5vw 3vw 0 0;
  visibility: hidden;
}

@media screen and (max-width: 550px) {
  .menu-btn {
    visibility: visible;
  }
}

.menu-btn:focus {
  opacity: 0.5;
}

.mobile-overlay {
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  visibility: hidden;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 100;
}

.mobile-overlay.active {
  visibility: visible;
  animation-name: mobile;
  animation-duration: 1s;
}


@keyframes mobile{
  from{ top: -50%}
  to{ transform: translate(-50%, -50%)}
}

.close-btn {
  color: #ffffff;
  background-color: transparent;
  position: fixed;
  top: 2.5vw;
  right: 4vw;
  font-size: 6vw;
  border: none;
}

.mobile-link {
  margin: 6vw;
  padding: 1vw;
  background: rgb(72, 61, 139);
}

.mobile-link:active {
  opacity: 0.5;
}

.mobile-container {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

.mobile-tab {
  color: #ffffff;
  text-decoration: none;
  width: 40vw;
  display: flex;
  justify-content: center;
  font-size: 4.8vw;
}

/* Home */

.home-body {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3vw;
}

@media screen and (max-width: 550px) {
  .home-body {
      flex-direction: column-reverse;
      margin-top: 5vw;
  }
}

.title {
  font-weight: 400;
  width: 17vw;
  font-size: 3.2vw;
  color: #ffffff;
  margin-top: 1vw;
}

@media screen and (max-width: 550px) {
  .title {
      color: rgba(255, 255, 255, 0);
      -webkit-text-stroke: #ffffff 0.6px;
      margin: 2vw auto;
      width: 80vw;
      text-align: center;
      font-size: 6vw;
  }
}

.jeff-titak {
  font-weight: 700;
  width: 32vw;
  color: #ffffff;
}

.designer {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: #ffffff 0.6px;
}

.designer:hover {
  color: #ffffff;
  text-shadow: 3px 3px 3px #F1B21B;
}

@media screen and (max-width: 550px) {
  .designer {
      color: #ffffff;
      text-shadow: 3px 3px 3px #F1B21B;
  }
}

.developer {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: #ffffff 0.6px;
}

.developer:hover {
  color: #ffffff;
  text-shadow: 3px 3px 3px #11DD44;
}

@media screen and (max-width: 550px) {
  .developer {
      color: #ffffff;
      text-shadow: 3px 3px 3px #11DD44;
  }
}

.react {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: #ffffff 0.6px;
}

.react:hover {
  color: #ffffff;
  text-shadow: 3px 3px 3px #DD11DD;
}

@media screen and (max-width: 550px) {
  .react {
      color: #ffffff;
      text-shadow: 3px 3px 3px #DD11DD;
  }
}

.portrait-desktop {
  height: 32vw;
  display: initial;
}

@media screen and (max-width: 550px) {
  .portrait-desktop {
      visibility: hidden;
      display: none;
  }
}

.portrait-mobile {
  width: 80vw;
  visibility: hidden;
  display: none;
  margin: 6vw auto 6vw;
}

@media screen and (max-width: 550px) {
  .portrait-mobile {
      visibility: visible;
      display: initial;
  }
}

.home-button {
  background: linear-gradient(90deg, rgba(193,209,54,1) 0%, rgba(233,241,163,1) 50%, rgba(193,209,54,1) 100%);
  display: flex;
  margin: 5vw auto 0;
  border: 2px rgb(21,23,64) solid;
  border-radius: 100px;
}

.home-button:hover {
  opacity: 0.75;
}

.home-button_text {
  padding: 1.4vw;
  text-decoration: none;
  color: rgb(21,23,64);
  width: 24vw;
  font-size: 2.2vw;
  text-transform: uppercase;
  font-weight: 700;
}

@media screen and (max-width: 550px) {
  .home-button_text {
      padding: 2vw;
      width: 48vw;
      font-size: 3.6vw;
  }
}

/* About Page */

.about-title {
  font-family: 'Playfair Display SC', serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 2vw;
  text-align: center;
  margin-bottom: 2vw;
}

@media screen and (max-width: 550px) {
  .about-title {
    font-size: 3.6vw;
    margin-top: 2vw;
  }
}

.wow {
  font-size: 5vw;
  color: #ffffff;
  font-family: 'Playfair Display SC', serif;
  font-weight: 700;
}

.about-body {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 550px) {
  .about-body {
    display: grid;
  }
}

.about-paragraphs-wrapper {
  width: 18vw;
  text-align: center;
  margin: auto 0;
}

@media screen and (max-width: 550px) {
  .about-paragraphs-wrapper {
    order: 2;
    margin: auto;
    width: 80vw;
  }
}

.about-paragraph {
  color: #ffffff;
  font-weight: 400;
  font-size: 1.3vw;
}

@media screen and (max-width: 550px) {
  .about-paragraph {
    font-size: 2.8vw;
  }
}

.paragraph-link {
  color: #ffffff;
}

.about-portrait {
  margin: auto 5vw;
  width: 42vw;
}

@media screen and (max-width: 550px) {
  .about-portrait {
    order: 1;
    margin: 0 auto 3vw;
  }
}

.characteristics {
  text-align: center;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 550px) {
  .characteristics {
    order: 3;
    display: flex;
    flex-direction: column;
  }
}

.characteristics-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.75vw;
  margin: 2vw auto 1vw;
}

@media screen and (max-width: 550px) {
  .characteristics-title {
    font-size: 3.4vw;
  }
}

.characteristics-body {
  color: #ffffff;
  font-weight: 400;
  font-size: 1.35vw;
}

@media screen and (max-width: 550px) {
  .characteristics-body {
    font-size: 2vw;
  }
}

.characteristics-link {
  color: #ffffff;
  font-weight: 400;
  font-size: 1.35vw;
}

.characteristics-link:hover {
  opacity: 0.5;
}

.characteristics-link:active {
  color: #ffffff;
}

@media screen and (max-width: 550px) {
  .characteristics-link {
    font-size: 2vw;
  }
}


.resume {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.75vw;
  margin: 1vw auto;
}

@media screen and (max-width: 550px){
  .resume {
    font-size: 3vw;
  }
}

.resume-link {
  color: #ffffff;
}

.resume-link:hover {
  opacity: 0.5;
}

.resume-link:active {
  color: #ffffff;
}

/* Portfolio Page */

.portfolio-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #ffffff;
  margin: 4vw auto;
  font-size: 4vw;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 550px) {
  .portfolio-title {
      font-size: 5.8vw;
  }
}

.grid {
  display: grid;
  grid-template-columns: 40vw 40vw;
  grid-gap: 2vw 4vw;
  justify-content: center;
  list-style: none;
  margin-bottom: 4vw;
}

@media screen and (max-width: 550px) {
  .grid {
      grid-template-columns: 80vw;
      grid-gap: 2vw;
  }
}

.grid-item {
  position: relative;
  object-fit: contain;
  z-index: 4;
}

.grid-image {
  width: 40vw;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 550px) {
  .grid-image {
      width: 80vw;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 40vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  opacity: 0;
  z-index: 100;
}

@media screen and (max-width: 550px) {
  .overlay {
      width: 80vw;
      opacity: 1;
  }
}

@media screen and (max-width: 550px) {
  .overlay > .overlay_link:focus {
      background: rgba(0, 0, 0, 0.3);
  }
}

.overlay:hover {
  opacity: 1;
}

.overlay_title {
  font-family: 'Roboto', sans-serif;
  font-size: 4vw;
  width: 80%;
  text-align: center;
  margin: auto;
}

.overlay_link {
  color: #ffffff;
  text-decoration: none;
  height: 100%;
  display: flex;
}

/*Contact Page (For image CSS - see home page) */

.contact-upper-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2vw;
}

@media screen and (max-width: 550px) {
  .contact-upper-wrapper {
    flex-direction: column;
    margin: auto;
  }
}

.contact-text-wrapper {
  display: flex;
  flex-direction: column;
  width: 46vw;
  justify-content: space-evenly;
  text-align: center;
}

@media screen and (max-width: 550px) {
  .contact-text-wrapper {
    width: auto;
  }
}

.contact-title {
  color: #ffffff;
  font-family: 'Playfair Display SC', serif;
  font-weight: 700;
  font-size: 3.6vw;
}

@media screen and (max-width: 550px) {
  .contact-title {
    font-size: 5.8vw;
    width: 80vw;
    margin: 2vw auto 0;
  }
}

.contact-description {
  color: #ffffff;
  font-size: 2.4vw;
}

@media screen and (max-width: 550px) {
  .contact-description {
    font-size: 3.8vw;
    width: 80vw;
    margin: auto;
  }
}

.contact-lower-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 8vw;
}

@media screen and (max-width: 550px) {
  .contact-lower-wrapper {
    flex-direction: column;
    margin: 4vw auto 2vw;
  }
}

.contact-info {
  color: #ffffff;
  font-size: 1.8vw;
}

@media screen and (max-width: 550px) {
  .contact-info {
    font-size: 3vw;
    display: flex;
    justify-content: space-between;
    margin: 0 22.5vw 1.5vw 0;
  }
}

@media screen and (max-width: 550px) {
  .contact-info:nth-child(2) {
    margin-right: 27.5vw;
  }
}

.contact-info:nth-child(3):hover {
  opacity: 0.5;
}

@media screen and (max-width: 550px) {
  .contact-info:nth-child(3) {
    margin: auto;
  }
}

@media screen and (max-width: 550px) {
  .contact-icon {
    margin-left: 35vw;
  }
}

/* Projects Page */

.projects-body {
  display: flex;
  justify-content: center;
  margin-top: 4vw;
}

@media screen and (max-width: 550px) {
  .projects-body {
      flex-direction: column-reverse;
  }
}

.projects-paragraph {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  width: 30vw;
  font-size: 1.8vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-right: 5vw;
}

@media screen and (max-width: 550px) {
  .projects-paragraph {
      width: 80vw;
      font-size: 3.4vw;
      margin: auto;
  }
}

.projects-image {
  width: 40vw;
  margin-left: 5vw;
}

@media screen and (max-width: 550px) {
  .projects-image {
      width: 80vw;
      margin: 4vw auto;
  }
}

.projects-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 4vw;
}

.project-link {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 2vw;
}

@media screen and (max-width: 550px) {
  .project-link {
      font-size: 3.4vw;
  }
}

.project-link:hover {
  opacity: 0.5;
}